<template>
  <div class="container">
    <header-bar></header-bar>
    <div class="yh_btn">
      <el-button class="btn btn_blue el-icon-arrow-right" @click.stop="open(true)">
        {{ !isExpand ? "展开" : "收起" }}
      </el-button>
      <el-button class="btn btn_orange el-icon-plus" @click.stop="add()">新建</el-button>
    </div>
    <el-table
      :data="treeData"
      ref="selectTree"
      row-key="deptId"
      :header-cell-class-name="headerStyle"
      :cell-style="isCenter"
      highlight-current-row
      border
      lazy
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      <el-table-column label="组织名称" prop="deptName">
        <!-- <template slot-scope="scope">
          <span v-if="scope.row.children.length > 0">
            {{ scope.row.deptName }} -->
        <template slot-scope="scope">
          <span v-if="scope.row.children && scope.row.children.length > 0">
            {{ scope.row.deptName }}
          </span>
          <span v-else class="rgba_lf">
            {{ scope.row.deptName }}
          </span>
        </template>
      </el-table-column>

      <el-table-column label="状态" prop="status">
        <template slot-scope="scope">
          <span v-if="scope.row.status === '0'" class="statusSign">停用</span>
          <span v-else>正常</span>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" prop="createTime" />
      <el-table-column label="操作" width="350">
        <template slot-scope="scope">
          <span
            class="operate color_blue"
            @click="add(scope.row,3)"
          >
            编辑
          </span>
          <span
            class="operate color_blue"
            v-if="scope.row.children && scope.row.children.length > 0"
            @click="add(scope.row,4)"
          >
            新建
          </span>
          <span
            class="operate color_red"
            @click="add(scope.row,5)"
          >
            删除
          </span>
          <!-- <span
            v-for="(item) in operation"
            :key="item.id"
            class="operate"
            :class="[{'color_blue' : item.ind == 3},{'color_blue': item.ind == 4},{'color_red': item.ind == 5}]"
            @click="add(scope.row,item.ind)"
          >
            {{item.name}}
          </span> -->
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      class="dialog_wrapper"
      :close-on-click-modal="false"
      :title="dialogTitle"
      width="450px"
      :visible.sync="isAddOrganiza"
    >
      <add-organiza :index="index" :itemData="itemData" :ctreeData="ctreeData" @closeOrgDia="closeDig" />
    </el-dialog>
  </div>
</template>

<script>
import API from "@apis/backStageEndAPI/organizaManagementAPI";
import headerBar from "@comps/backStageComponent/layout/headerBar/headerBar";
import addOrganiza from "./components/addOrganiza";

export default {
  name: "organizaManagement",
  components: {
    headerBar,
    addOrganiza,
  },
  data() {
    return {
      isExpand: false,
      isAddOrganiza: false,
      dialogTitle: "新建菜单",
      itemData: {},
      treeData: [],
      ctreeData: [],
      operation: [
        { ind: 3, name: "编辑" },
        { ind: 4, name: "新建" },
        { ind: 5, name: "删除" },
      ],
      index: 0,
    };
  },
  mounted() {
    this.getOrganizaList();
  },
  methods: {
     // 设置表头样式
    headerStyle({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 15) {
        return 'table-th'
      } else if (columnIndex === 0 || columnIndex === 1) {
        return 'table-lt'
      } else {
        return 'table-rt'
      }
    },
    // 设置单元格样式
    isCenter({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 15) {
        return 'text-align: center'
      } else if (columnIndex === 0) {
        return 'text-align: left; '
      } else {
        return 'text-align: center'
      }
    },
    closeDig(val) {
      this.getOrganizaList();
      this.isAddOrganiza = false;

    },
    async getOrganizaList() {
      const status = "";
      let res = await API.getDeptList(status);
      this.treeData = res;
      this.ctreeData = res;
    },
    open(isAss) {
      this.isExpand = !this.isExpand;
      this.$nextTick(() => {
        this.forArr(this.treeData, this.isExpand);
      });
    },
    forArr(arr, isExpand) {
      arr.forEach(i => {
        this.$refs.selectTree.toggleRowExpansion(i, isExpand);
        if (i.children) {
          this.forArr(i.children, isExpand);
        }
      });
    },
    add(row, ind) {
      this.index = ind | 0;
      this.itemData = Object.assign({}, row);
      if (ind === 3) {
        this.dialogTitle = "编辑组织";
        this.isAddOrganiza = true;
      } else if (!ind || ind === 4) {
        this.dialogTitle = "新建组织";
        this.isAddOrganiza = true;
      } else if (ind === 5) {
        this.$confirm("您是要删除当前记录吗？", "提示", {
          confirmButtonText: "确定",
          cancleButtonText: "取消",
          type: "warning",
          center: true,
        })
          .then(async () => {
            let res = await API.delDept(row.deptId);
            // this.$message.info(res.msg);
            this.getOrganizaList();
          })
          .catch(() => {
            this.$message.info("已取消删除");
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table__placeholder {
	width: 0 !important;
}
.rgba_lf {
	margin-left: 30px !important;
}
.yh_btn {
	margin: 0 0 8px 5px;
}
.statusSign {
	color: pink;
}
::v-deep {
	.el-table {
		td,
		th {
			padding: 6px 0;
			color: #222;
			text-align: center;
			font-size: 16px;
		}
		.cell .yh_span {
			margin: 0 15px;
			text-decoration: underline;
			font-size: 16px;
			cursor: pointer;
		}
	}
	.el-table .el-table__cell {
		padding: 10px 0;
	}
	.el-table th.el-table__cell {
		border-radius: 3%;
	}
}
</style>
