<template>
  <div class="yh_container">
    <el-form class="form" ref="form" :model="formData" :rules="rules" :label="labelWidth">
      <el-row>
        <el-col v-if="ind == 4 || ind == 0" :span="24">
          <el-form-item label="上级组织">
            <el-cascader
              ref="CascaderRef"
              :options="ctreeData"
              v-model="parentId"
              :props="treeProps"
              :show-all-levels="false"
              @change="cascaderChange"
              clearable
              :disabled="ind == 4"
              placeholder="/"
            />
            <!--   -->
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item
            label="组织名称"
            prop="deptName"
            :rules="{
              required: true,
              trigger: 'blur',
              message: '组织名称不能为空'
            }"
          >
            <el-input
              v-model="formData.deptName"
              type="text"
              placeholder="请输入组织名称"
              clearable
              maxlength="50"
              show-word-limit
            />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="状态">
            <el-radio-group v-model="formData.status">
              <el-radio :label="'1'">正常</el-radio>
              <el-radio :label="'0'">停用</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="yh_btn">
      <el-button class="btn btn_blue" @click="save">提交</el-button>
      <el-button class="btn btn_cancle btn_normal" @click="cancle">取消</el-button>
    </div>
  </div>
</template>

<script>
import API from '@apis/backStageEndAPI/organizaManagementAPI';

export default {
  name: 'addOrganiza',
  props: ['itemData', 'ctreeData', 'index'],
  watch: {
    ctreeData: {
      handler(newVal) {
        this.delChildNull(newVal)
      },
      deep: true,
      immediate: true
    },
    itemData: {
      handler(newVal) {
        this.initData(newVal);
      },
      deep: true,
      immediate: true
    },
    index: {
      handler(newVal) {
        this.ind = newVal;
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      ind: 0,
      labelWidth: '120px',
      formData: {
        parentId: '', //父部门id
        deptId: '', //部门id
        deptName: '', //名称
        status: '' //状态：0|禁用，1|启用
      },
      rules: {},
      parentId: [],
      treeProps: {
        value: 'deptId',
        label: 'deptName',
        children: 'children',
        checkStrictly: true
      }
    };
  },
  mounted() {
    this.initData(this.itemData);
  },
  methods: {
    delChildNull(data) { //
      data.forEach(item => {
        if (item.children.length > 0) {
          this.delChildNull(item.children)
        } else {
          delete item.children
        }
      })
    },
    initData(data) {
      this.formData = Object.assign({}, data);
      this.parentId = [];
      let tem = this.formData.ancestors ? this.formData.ancestors.split(',') : [];
      tem.length && tem.shift();
      tem.length &&
        tem.forEach((element) => {
          this.parentId.push(Number.parseInt(element));
        });
      if (this.index === 4) {
        this.parentId.push(this.formData.deptId);
        this.formData.deptName = '';
        this.formData.deptId = ''
      }
    },
    cascaderChange(val) {
      this.$refs.CascaderRef.dropDownVisible = false
      this.formData.parentId = val[val.length - 1];
    },
    async save() {
      const params = Object.assign({}, this.formData);
      let res = {};
      if (!params.deptId) {
        params.parentId = this.parentId.pop()
        res = await API.addDept(params);
      } else {
        res = await API.updateDept(params);
      }
      res && this.$emit('closeOrgDia', true);
    },
    cancle() {
      this.$emit('closeOrgDia', true);
    }
  }
};
</script>

<style lang="scss" scoped>
.yh_container {
	position: relative;
	.title {
		margin-bottom: 20px;
		text-align: center;
		font-size: 18px;
	}
	.yh_btn {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	::v-deep .el-cascader {
		width: 100%;
	}
}
</style>
